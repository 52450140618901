import axios, { HttpStatusCode } from "axios";
import { createContext } from "react";

export type WhoAmIResponse = {
    userId: string;
    name: string;
    roles: string[];
    permissions: string[];
};

export type WhoAmIContext =
    | {
          key: string;
          value: WhoAmIResponse | null;
      }
    | {
          key: "function";
          value: () => Promise<WhoAmIResponse | null>;
      };

export const whoAmIContext = createContext<WhoAmIContext>({
    key: "function",
    value: async () => {
        const response = await axios.get<WhoAmIResponse>("/api/auth/who-am-i", {
            validateStatus(status) {
                return status === 401 || status === 200;
            },
        });
        if (response.status === HttpStatusCode.Unauthorized) return null;

        return response.data;
    },
});
