import { ThemeProvider, createTheme } from "@mui/material/styles";
import { PropsWithChildren } from "react";

const theme = createTheme({
    typography: {
        subtitle1: {
            fontSize: "17px",
        },
        body1: {
            fontSize: "15px",
        },
    },
});

export default function FlThemeProvider({ children }: PropsWithChildren) {
    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
