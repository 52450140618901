import { useIdentity } from "../../../features/security";
import FlAccountMenuView from "./FlAccountMenuView";

const navigateToLogout = () => {
    window.location.href = "/api/auth/logout";
};

export default function FlAccountMenu() {
    const identity = useIdentity(false);

    return (
        <FlAccountMenuView
            identity={identity}
            onLogoutClicked={navigateToLogout}
        />
    );
}
