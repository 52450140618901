import { Map } from "@mui/icons-material";
import { forwardRef } from "react";

interface FlRoutePlanningIconProps {}

const FlRoutePlanningIcon = forwardRef<SVGSVGElement, FlRoutePlanningIconProps>(function FlRoutePlanningIcon(_, ref) {
    return <Map ref={ref} />;
});

export default FlRoutePlanningIcon;
