import { SvgIcon, Tooltip } from "@mui/material";
import { t } from "i18next";
import { NavLink } from "react-router-dom";
import { FlApp } from "../../../apps";
import { FlRequirePermission } from "../../../features/security";

const InlineAppItems = ({ apps }: { apps: FlApp[] }) => {
    return (
        <div style={{ display: "flex", gap: ".25rem", marginRight: ".5rem" }}>
            {apps.map((app) => (
                <FlRequirePermission
                    all={app.allPermissions}
                    key={app.name}
                    permission={app.permissions}
                >
                    <NavLink
                        key={app.name}
                        to={app.route}
                    >
                        {({ isActive }) => (
                            <>
                                <Tooltip
                                    title={t(app.name)}
                                    arrow
                                >
                                    <SvgIcon
                                        className={`icon-${app.name}`}
                                        sx={{
                                            color: isActive ? "#E6E6E6" : "rgba(0, 0, 0, 0.54)",
                                            p: "8px",
                                            backgroundColor: isActive ? "rgba(0, 0, 0, 0.08)" : "unset",
                                            borderRadius: 100,
                                            "&:hover": {
                                                backgroundColor: "rgba(0, 0, 0, 0.04)",
                                            },
                                            transition: "background-color .2s ease-in-out, color .2s ease-in-out",
                                        }}
                                    >
                                        {app.icon}
                                    </SvgIcon>
                                </Tooltip>
                            </>
                        )}
                    </NavLink>
                </FlRequirePermission>
            ))}
        </div>
    );
};

export default InlineAppItems;
