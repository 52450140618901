import { PropsFor } from "@mui/system";
import { forwardRef } from "react";
import FlButton, { FlButtonOwnProps } from "./FlButton";

export type FlPrimaryButtonOwnProps = Omit<FlButtonOwnProps, "variant" | "color">;

const FlPrimaryButton = forwardRef<HTMLButtonElement, FlPrimaryButtonOwnProps>(function FlPrimaryButton({ children, ...props }, ref) {
    return (
        <FlButton
            {...props}
            color="primary"
            ref={ref}
            variant="contained"
        >
            {children}
        </FlButton>
    );
});

export type FlPrimaryButtonProps = PropsFor<typeof FlPrimaryButton>;

export default FlPrimaryButton;
