import { Stack, StackProps } from "@mui/material";

export type FlStackProps = StackProps;

const FlStack = (props: FlStackProps) => (
    <Stack
        useFlexGap
        {...props}
    />
);

export default FlStack;
