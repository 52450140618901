import FlCustomerSupportIcon from "./designSystem/Icons/FlCustomerSupportIcon";
import FlFleetManagementIcon from "./designSystem/Icons/FlFleetManagementIcon";
import FlOfficeIcon from "./designSystem/Icons/FlOfficeIcon";
import FlPackingIcon from "./designSystem/Icons/FlPackingIcon";
import FlProductionIcon from "./designSystem/Icons/FlProductionIcon";
import FlRoutePlanningIcon from "./designSystem/Icons/FlRoutePlanningIcon";
import FlSurveyorIcon from "./designSystem/Icons/FlSurveyorIcon";
import FlUsersIcon from "./designSystem/Icons/FlUsersIcon";
import FlVisitsIcon from "./designSystem/Icons/FlVisitsIcon";
import { RequiredPermission } from "./features/security/useIdentity";
import { Permissions } from "./permissions";

export type FlApp = {
    route: string;
    name: string;
    icon: JSX.Element;
    permissions?: RequiredPermission;
    allPermissions?: boolean;
};

const apps: FlApp[] = [
    {
        name: "features.customerSupport.customers.appName",
        icon: <FlCustomerSupportIcon />,
        route: "/customers",
        permissions: Permissions.Customers.Read,
    },
    {
        name: "features.customerSupport.visits.appName",
        icon: <FlVisitsIcon />,
        route: "/visits",
        permissions: Permissions.Visits.Read,
    },
    {
        name: "features.routePlanning.appName",
        icon: <FlRoutePlanningIcon />,
        route: "/route-planning/routes",
        permissions: Permissions.Routes.Read,
    },
    {
        name: "features.administration.users.appName",
        icon: <FlUsersIcon />,
        route: "/users",
        permissions: Permissions.Users.Read,
    },
    {
        name: "features.administration.fleet.appName",
        icon: <FlFleetManagementIcon />,
        route: "/fleet",
        permissions: Permissions.Vehicles.Read,
    },
    {
        name: "features.surveyor.appName",
        icon: <FlSurveyorIcon />,
        route: "/surveyor",
        allPermissions: false,
        permissions: [Permissions.FlyScreens.Read, Permissions.SalesQuotes.Read, Permissions.SalesOrders.Read],
    },
    {
        name: "features.office.appName",
        icon: <FlOfficeIcon />,
        route: "/office",
        allPermissions: false,
        permissions: [Permissions.Office.Read, Permissions.Developer],
    },
    {
        name: "features.production.appName",
        icon: <FlProductionIcon />,
        route: "/production",
        allPermissions: false,
        permissions: [Permissions.Production.Read, Permissions.Developer],
    },
    {
        name: "features.packing.appName",
        icon: <FlPackingIcon />,
        route: "/packing",
        allPermissions: false,
        permissions: [Permissions.Packing.Read, Permissions.Developer],
    },
];

export default apps;
