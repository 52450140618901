import { Container } from "@mui/material";
import React, { useMemo, useRef, useState } from "react";
import { PageTitle, PageTitlePartsContext, useCurrentPageTitle, usePageTitle } from "../../hooks/usePageTitle";
import FlHeader, { FlHeaderProps } from "./FlHeader";

type FlPageBaseProps = React.PropsWithChildren<Pick<FlHeaderProps, "title" | "tools">>;

export default function FlPageBase({ title, tools, children }: FlPageBaseProps) {
    const titleParts = useRef<PageTitle[]>([]);
    const [rerender, setRerender] = useState(0);

    const contextValue = useMemo(
        () => ({
            value: titleParts.current,
            rerender: () => setRerender(rerender + (1 % 10)),
            rerenderValue: rerender,
        }),
        [rerender]
    );

    return (
        <PageTitlePartsContext.Provider value={contextValue}>
            <FlPageView
                title={title}
                tools={tools}
            >
                {children}
            </FlPageView>
        </PageTitlePartsContext.Provider>
    );
}

function FlPageView({ title, tools, children }: FlPageBaseProps) {
    usePageTitle(() => title, [title]);

    const parts = useCurrentPageTitle();

    return (
        <>
            <FlHeader
                title={parts}
                tools={tools}
            />
            <Container
                maxWidth="lg"
                style={{
                    paddingTop: "1rem",
                }}
            >
                {children}
            </Container>
        </>
    );
}
