import { Close } from "@mui/icons-material";
import { Alert, DialogContent, DialogTitle, IconButton, Snackbar } from "@mui/material";
import { MutationCache, QueryCache, QueryClient, QueryClientProvider } from "@tanstack/react-query";
import anylogger from "anylogger";
import { AxiosError, HttpStatusCode } from "axios";
import { PropsWithChildren, ReactNode, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import FlText from "./designSystem/DataDisplay/FlText";
import FlFullscreenDialog from "./features/surveyor/NewMeasurement/ProductMeasurements/Addons/FlFullscreenDialog";

const log = anylogger("FluenetQueryClientProvider");
let setQueryError: (error: Error) => void = () => {};

const queryClient = new QueryClient({
    queryCache: new QueryCache({
        onError: (error) => {
            log.error(`Error in query execution: ${error.message}`, error);
            setQueryError(error);
        },
    }),
    mutationCache: new MutationCache({
        onError: (error) => {
            log.error(`Error in mutation execution: ${error.message}`, error);
            setQueryError(error);
        },
    }),
});

export default function FluenetQueryClientProvider({ children }: PropsWithChildren) {
    const { t } = useTranslation();
    const [error, setError] = useState<Error | undefined>(undefined);
    const [isShowingErrorDetails, setIsShowingErrorDetails] = useState(false);
    setQueryError = setError;

    function closeError() {
        setIsShowingErrorDetails(false);
        setError(undefined);
    }

    const renderedError = useMemo(() => (error ? parseError(error) : null), [error]);

    return (
        <>
            <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
            <Snackbar
                autoHideDuration={6000}
                onClick={() => setIsShowingErrorDetails(true)}
                onClose={() => {
                    !isShowingErrorDetails && setError(undefined);
                }}
                open={Boolean(error)}
            >
                <Alert
                    severity="error"
                    variant="filled"
                >
                    {t("common.unexpectedQueryError", { error: renderedError })}
                </Alert>
            </Snackbar>

            <FlFullscreenDialog
                onClose={closeError}
                open={isShowingErrorDetails}
            >
                <DialogTitle sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    {t("common.unexpectedQueryError", { error: renderedError })}
                    <IconButton onClick={closeError}>
                        <Close />
                    </IconButton>
                </DialogTitle>

                <DialogContent
                    sx={{
                        "& pre": {
                            whiteSpace: "pre-wrap",
                            wordBreak: "break-all",
                        },
                    }}
                >
                    {error && (
                        <>
                            <FlText
                                bold
                                variant="h6"
                            >
                                Error Name
                            </FlText>
                            <FlText
                                component="pre"
                                variant="body2"
                            >
                                {error.name}
                            </FlText>

                            <FlText
                                bold
                                variant="h6"
                            >
                                Error Stack
                            </FlText>
                            <FlText
                                component="pre"
                                variant="body2"
                            >
                                {error.stack}
                            </FlText>

                            <FlText
                                bold
                                variant="h6"
                            >
                                Error Message
                            </FlText>
                            <FlText
                                component="pre"
                                variant="body2"
                            >
                                {error.message}
                            </FlText>

                            {error instanceof AxiosError && (
                                <>
                                    <FlText
                                        bold
                                        variant="h6"
                                    >
                                        Axios Response
                                    </FlText>
                                    <FlText
                                        component="pre"
                                        variant="body2"
                                    >
                                        {JSON.stringify(error.response?.data, null, 2)}
                                    </FlText>

                                    <FlText
                                        bold
                                        variant="h6"
                                    >
                                        Axios Request
                                    </FlText>
                                    <FlText
                                        component="pre"
                                        variant="body2"
                                    >
                                        {JSON.stringify(
                                            {
                                                headers: error.config?.headers,
                                                data: JSON.parse(error.config?.data),
                                                method: error.config?.method,
                                                url: error.config?.url,
                                            },
                                            null,
                                            2,
                                        )}
                                    </FlText>
                                </>
                            )}
                        </>
                    )}

                    {!error && <FlText>No error details available.</FlText>}
                </DialogContent>
            </FlFullscreenDialog>
        </>
    );
}

function parseError(error: Error): ReactNode {
    if (error instanceof AxiosError) {
        const errorStatus = error.response?.status;
        if (errorStatus == HttpStatusCode.UnprocessableEntity) {
            const problemDetails = error.response?.data;
            if ("type" in problemDetails && problemDetails.type == "https://httpstatuses.io/422" && typeof problemDetails.errors == "object") {
                return Object.values(problemDetails.errors)
                    .flatMap((e) => (Array.isArray(e) ? e : [e]))
                    .join("\n");
            }
        }

        if (errorStatus == HttpStatusCode.InternalServerError && error.response?.data && "detail" in error.response.data) {
            return error.response.data.detail;
        }
    }

    return error.message;
}
