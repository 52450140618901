import dayjs from "dayjs";
import "dayjs/locale/da";
import localizedFormat from "dayjs/plugin/localizedFormat";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import i18next from "i18next";
import nodatimeExtensions from "./dayjsExtensions/nodatimeExtensions";
import da from "./locales/da.json";
const missingKeys: Set<string> = new Set();
const missingKeyHandler = (_: readonly string[], _ns: string, key: string) => {
    missingKeys.add(key);
};

(window as { getMissingTranslations?: () => string[] }).getMissingTranslations = () => {
    return Array.from(missingKeys);
};

i18next.init({
    interpolation: { escapeValue: false }, // React already does escaping
    fallbackLng: "da",
    lng: "da",
    resources: {
        da: {
            translation: da,
        },
    },
    saveMissing: !import.meta.env.PROD,
    missingKeyHandler,
});

export const $t = i18next.t.bind(i18next);

dayjs.extend(localizedFormat);
dayjs.extend(updateLocale);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(nodatimeExtensions);

dayjs.locale("da");
dayjs.updateLocale("da", {
    formats: {
        ...dayjs.Ls.da.formats,
        L: "DD/MM-YY",
    },
});
