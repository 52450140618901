import axios from "axios";

axios.defaults.paramsSerializer = {
    indexes: null,
};

axios.interceptors.response.use(
    (config) => config,
    (error) => {
        if (error.response?.status === 401) {
            const url = document.location;
            const pathAndQuery = url.pathname + url.search;

            window.location.href = `/login?post_login_redirect_uri=${encodeURIComponent(pathAndQuery)}`;
        }

        return Promise.reject(error);
    },
);
