import { Typography, TypographyOwnProps } from "@mui/material";
import { ElementType, PropsWithChildren, useMemo } from "react";
import { useTranslation } from "react-i18next";

export type FlTextProps = {
    variant?: TypographyOwnProps["variant"];
    sx?: TypographyOwnProps["sx"];
    inline?: boolean;
    bold?: boolean;
    color?: string;
    component?: ElementType;
} & (
    | PropsWithChildren<{ localized?: undefined }>
    | {
          localized: string | { key: string; args?: Record<string, unknown> };
          children?: undefined;
      }
);

export default function FlText({ sx, bold, variant, localized, inline, children, color, component }: FlTextProps) {
    if (bold) {
        sx = { ...sx, fontWeight: "bold" };
    }

    const { t } = useTranslation();

    const actualChildren = useMemo(() => {
        if (localized) {
            if (typeof localized === "string") return t(localized);
            return t(localized.key, localized.args);
        }
        return children;
    }, [children, localized, t]);

    return (
        <Typography
            color={color}
            display={inline ? "inline" : undefined}
            sx={sx}
            variant={variant}
            {...(component ? { component } : {})}
        >
            {actualChildren}
        </Typography>
    );
}
