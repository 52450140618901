import { People } from "@mui/icons-material";
import { forwardRef } from "react";

interface FlUsersIconProps {}

const FlUsersIcon = forwardRef<SVGSVGElement, FlUsersIconProps>(function FlUsersIcon(_, ref) {
    return <People ref={ref} />;
});

export default FlUsersIcon;
