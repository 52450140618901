import { axiosErrorDestructurer } from "./axiosErrorDestructurer";
import { objectDestructurer } from "./objectDestructurer";
import { Destructurer } from "./types";

const destructurers: Destructurer[] = [axiosErrorDestructurer, objectDestructurer];

export function destructure(o: undefined): undefined;
export function destructure(o: null): null;
export function destructure<T>(o: T[]): unknown[];
export function destructure(o: unknown): unknown;
export function destructure(o: unknown): unknown {
    if (o !== undefined && o !== null) {
        for (const d of destructurers) {
            const result = d(o, destructure);
            if (result !== undefined) {
                return result;
            }
        }
    }

    return o;
}
