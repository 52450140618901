import { Inventory } from "@mui/icons-material";
import { forwardRef } from "react";

interface FlPackingIconProps {}

const FlPackingIcon = forwardRef<SVGSVGElement, FlPackingIconProps>(function FlPackingIcon(_, ref) {
    return <Inventory ref={ref} />;
});

export default FlPackingIcon;
