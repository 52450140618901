import dayjs, { PluginFunc } from "dayjs";

declare module "dayjs" {
    interface Dayjs {
        toZonedDateTime(): string;
        toInstant(): string;
    }

    export function fromZonedDateTime(zonedNodatime: string): Dayjs;
    export function fromInstant(instant: string): Dayjs;
}

const DateAndTimeFormat = "YYYY-MM-DDTHH:mm:ss";
type DayJsClass = Parameters<PluginFunc>[1];
type DayJsFactory = Parameters<PluginFunc>[2];
const plugin: PluginFunc = (_, dayjsClass, dayjsFactory) => {
    extendWithZonedDateTime(dayjsClass, dayjsFactory);
    extendWithInstant(dayjsClass, dayjsFactory);
};
export default plugin;


function extendWithInstant(dayjsClass: DayJsClass, dayjsFactory: DayJsFactory) {
    dayjsFactory.fromInstant = (instant: string) => {
        return dayjs(instant);
    }

    dayjsClass.prototype.toInstant = function () {
        return this.toISOString();
    };
}

function extendWithZonedDateTime(dayjsClass: DayJsClass, dayjsFactory: DayJsFactory) {
    dayjsClass.prototype.toZonedDateTime = function () {
        const timezone = dayjs.tz.guess();
        return `${this.format(`${DateAndTimeFormat} [${timezone}]`)}`;
    };

    dayjsFactory.fromZonedDateTime = (zonedNodatime: string) => {
        const parsed = tryExtractDateTimeAndZone(zonedNodatime);

        return dayjs(parsed.dateTime, DateAndTimeFormat).tz(parsed.zone);
    };
}

function tryExtractDateTimeAndZone(zonedDate: string) {
    const [dateTime, zoneUnformatted, offset] = zonedDate.split(" ") as [string, string, string | undefined];

    const zone = zoneUnformatted;
    const utcOffset = offset?.substring(1, offset.length - 1);
    return { zone, dateTime, utcOffset };
}
