import { TFunction } from "i18next";
import { FlApp } from "../../../apps";
import FlRequirePermission from "../../../features/security/FlRequirePermission";
import { FlAppItem } from "./FlAppItem";

export function FlAppItems(props: FlAppItemsProps) {
    return props.apps.map((app, i) => (
        <FlRequirePermission
            all={app.allPermissions}
            key={app.name}
            permission={app.permissions}
        >
            <FlAppItem
                autoFocus={i === 0}
                icon={app.icon}
                key={app.name}
                name={props.t(app.name)}
                onClick={props.onAppClicked}
                route={app.route}
            />
        </FlRequirePermission>
    ));
}

interface FlAppItemsProps {
    apps: FlApp[];
    onAppClicked?: () => void;
    t: TFunction;
}
