import { Container, styled } from "@mui/material";
import { Fragment, ReactNode } from "react";
import FlText from "../../designSystem/DataDisplay/FlText";
import FlLink from "../../designSystem/Navigation/FlLink";
import { PageTitle, useTitleRenderer } from "../../hooks/usePageTitle";

function TitlePartView({ value, path }: Readonly<{ value: string; path?: string }>) {
    if (path) {
        return (
            <FlLink
                text={value}
                to={path}
            />
        );
    } else {
        return value;
    }
}

export type FlHeaderProps = {
    title?: PageTitle;
    tools?: ReactNode;
};

export default function FlHeader({ title, tools }: Readonly<FlHeaderProps>) {
    const titleRenderer = useTitleRenderer(title, TitlePartView, " > ", (a) => a.map((e, i) => <Fragment key={i}>{e}</Fragment>));

    return (
        <StyledHeader>
            <MuiContainer maxWidth="lg">
                <FlText>{titleRenderer}</FlText>

                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    {tools}
                </div>
            </MuiContainer>
        </StyledHeader>
    );
}

const StyledHeader = styled("header")(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    width: "100%",
}));

const MuiContainer = styled(Container)(() => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: ".2rem",
    paddingBottom: ".2rem",
    minHeight: "50px",
    maxHeight: "50px",
    overflow: "hidden",
}));
