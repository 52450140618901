export const Permissions = {
    Visits: {
        Create: "customer-support/visits/create",
        Read: "customer-support/visits",
        Update: "customer-support/visits/update",
    },
    Inquiries: {
        Create: "customer-support/inquiries/create",
        Read: "customer-support/inquiries",
        Update: "customer-support/inquiries/update",
    },
    Customers: {
        Create: "customer-support/customers/create",
        Read: "customer-support/customers",
        Update: "customer-support/customers/update",
    },
    SalesQuotes: {
        Create: "customer-support/sales-quotes/create",
        Read: "customer-support/sales-quotes",
        Update: "customer-support/sales-quotes/update",
    },
    SalesOrders: {
        Create: "customer-support/sales-orders/create",
        Read: "customer-support/sales-orders",
        Update: "customer-support/sales-orders/update",
    },
    FlyScreens: {
        Create: "customer-support/fly-screens/create",
        Read: "customer-support/fly-screens",
        Update: "customer-support/fly-screens/update",
        Delete: "customer-support/fly-screens/delete",
    },
    Routes: {
        Create: "route-planning/routes/create",
        Read: "route-planning/routes",
        Update: "route-planning/routes/update",
        Delete: "route-planning/routes/delete",
    },
    Vehicles: {
        Create: "route-planning/vehicles/create",
        Read: "route-planning/vehicles",
        Update: "route-planning/vehicles/update",
        Delete: "route-planning/vehicles/delete",
    },
    Users: {
        Create: "security/users/create",
        Read: "security/users",
        Update: "security/users/update",
        Delete: "security/users/delete",
    },
    Office: {
        Read: "office",
    },
    Production: {
        Read: "production",
    },
    Packing: {
        Read: "packing",
    },

    Developer: "developer",
} as const;
