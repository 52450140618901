import { useCallback, useMemo } from "react";
import {
    NavigateFunction,
    NavigateOptions,
    SetURLSearchParams,
    To,
    URLSearchParamsInit,
    useNavigate,
    useParams,
    useSearchParams,
} from "react-router-dom";

type KeepQuery = undefined | boolean | string | string[];

interface UseUrlQuery {
    get(key: string): string | undefined;
    get(key: string, options: { defaultValue: string }): string;
    get<T>(key: string, options: { parser: (value: string) => T }): T | undefined;
    get<T>(key: string, parser: (value: string) => T): T | undefined;
    get<T>(key: string, options: { parser: (value: string) => T; defaultValue: T | undefined }): T;

    set(nextInit: URLSearchParamsInit | ((prev: Record<string, string | string[]>) => URLSearchParamsInit | Record<string, unknown>)): void;

    raw: [URLSearchParams, SetURLSearchParams];
    toString(keepQuery?: KeepQuery): string;
}

function toPartialString(keepQuery: KeepQuery, searchParams: URLSearchParams) {
    if (!keepQuery) return searchParams.toString();
    if (keepQuery === true) return searchParams.toString();

    const keepQueryArr = Array.isArray(keepQuery) ? keepQuery : [keepQuery];
    const partialSearchParams = new URLSearchParams();
    keepQueryArr.forEach((k) => {
        const value = searchParams.get(k);
        if (value) partialSearchParams.set(k, value);
    });
    return partialSearchParams.toString();
}

export default function useUrl() {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const params = useParams();

    const getSearchParam = useCallback<UseUrlQuery["get"]>(
        <T>(key: string, optionsOrParser?: { parser?: (value: string) => T; defaultValue?: T } | ((value: string) => T)) => {
            const valueStr = searchParams.get(key);

            if (typeof optionsOrParser === "function") return valueStr ? optionsOrParser(valueStr) : undefined;

            if (!valueStr) return optionsOrParser?.defaultValue;
            if (optionsOrParser?.parser) return optionsOrParser.parser(valueStr);

            return valueStr;
        },
        [searchParams]
    );

    const setSearchParam = useCallback<UseUrlQuery["set"]>(
        (nextInit: URLSearchParamsInit | ((prev: Record<string, string | string[]>) => URLSearchParamsInit)) => {
            setSearchParams((prev) => {
                if (typeof nextInit !== "function") return nextInit;

                const prevObj = Object.fromEntries(prev.entries());
                const next = nextInit(prevObj);
                return next;
            });
        },
        [setSearchParams]
    );

    const goTo = useCallback<
        NavigateFunction | ((to: To | [path: string, search: object], options?: NavigateOptions & { keepQuery?: KeepQuery }) => void)
    >(
        (to: To | [path: string, search: object], options?: NavigateOptions & { keepQuery?: KeepQuery }) => {
            if (Array.isArray(to)) {
                to = {
                    pathname: to[0],
                    search: new URLSearchParams(to[1] as Record<string, string>).toString(),
                };
            }
            if (options?.keepQuery) {
                if (typeof to === "string") to = { pathname: to, search: toPartialString(options.keepQuery, searchParams) };
            }

            navigate(to, options);
        },
        [navigate, searchParams]
    );

    const result = useMemo(
        () => ({
            query: {
                get: getSearchParam,
                set: setSearchParam,
                raw: [searchParams, setSearchParams],
                toString: (k?) => toPartialString(k, searchParams),
            } satisfies UseUrlQuery,
            params,
            goTo,
        }),
        [getSearchParam, goTo, params, searchParams, setSearchParam, setSearchParams]
    );

    return result;
}

export type UseUrl = ReturnType<typeof useUrl>;
