import { Logout } from "@mui/icons-material";
import { IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Identity } from "../../../features/security/useIdentity";
import FlIdentityAvatar from "../FlIdentityAvatar";

interface FlAccountMenuViewProps {
    onLogoutClicked?: () => void;
    identity: Identity | null;
}
const FlAccountMenuView = (props: FlAccountMenuViewProps) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const tooltipTitle = useMemo(() => {
        if (!props.identity?.isAuthenticated) return undefined;

        return props.identity?.name;
    }, [props.identity]);

    return (
        <>
            <Tooltip
                arrow
                placement="bottom"
                title={tooltipTitle}
            >
                <IconButton
                    aria-controls={open ? "account-menu" : undefined}
                    aria-expanded={open ? "true" : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    size="small"
                >
                    <FlIdentityAvatar identity={props.identity} />
                </IconButton>
            </Tooltip>

            <Menu
                MenuListProps={{
                    "aria-labelledby": "account-menu",
                }}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                id="account-menu"
                onClick={handleClose}
                onClose={handleClose}
                open={open}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <LogoutMenuItem onClick={props.onLogoutClicked} />
            </Menu>
        </>
    );
};

const LogoutMenuItem = (props: { onClick?: () => void }) => {
    const { t } = useTranslation();

    return (
        <MenuItem onClick={props.onClick}>
            <ListItemIcon>
                <Logout fontSize="small" />
            </ListItemIcon>
            {t("common.logout")}
        </MenuItem>
    );
};

export default FlAccountMenuView;
