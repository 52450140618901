import { LoadingButton, LoadingButtonProps } from "@mui/lab";
import { ButtonProps } from "@mui/material";
import { PropsFor } from "@mui/system";
import { PropsWithChildren, forwardRef } from "react";

export type FlButtonOwnProps = PropsWithChildren<{ submit?: boolean }> &
    Pick<ButtonProps, "color" | "variant" | "startIcon" | "endIcon" | "onClick" | "disabled" | "sx" | "fullWidth" | "size"> &
    Pick<LoadingButtonProps, "loading" | "loadingIndicator" | "loadingPosition">;

const FlButton = forwardRef<HTMLButtonElement, FlButtonOwnProps>(function FlButton({ submit, children, ...props }, ref) {
    return (
        <LoadingButton
            {...props}
            fullWidth={props.fullWidth}
            ref={ref}
            sx={{
                width: "fit-content",
                ...props.sx,
            }}
            type={submit ? "submit" : "button"}
            variant={props.variant}
        >
            {children}
        </LoadingButton>
    );
});

export type FlButtonProps = PropsFor<typeof FlButton>;

export default FlButton;
