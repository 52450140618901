import { DependencyList, createContext, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import useUrl, { UseUrl } from "../pages/CustomerSupport/Customers/useUrl";

export type PageTitlePart =
    | string
    | null
    | undefined
    | { value: string; args?: { [key: string]: unknown }; path?: string; keepQuery?: true | string | string[] }
    | { key: string; args?: { [key: string]: unknown }; path?: string; keepQuery?: true | string | string[] };

export type PageTitle = PageTitlePart | PageTitlePart[];

export const PageTitlePartsContext = createContext<{
    value: PageTitle[];
    rerender: () => void;
}>({ value: [], rerender: () => {} });

export function useCurrentPageTitle(): PageTitle {
    const { value } = useContext(PageTitlePartsContext);

    const result = value.length === 0 ? undefined : value[value.length - 1];

    return result;
}

export function usePageTitle(factory: () => PageTitle, deps: DependencyList): PageTitle[] {
    const { value, rerender } = useContext(PageTitlePartsContext);

    const setThisTitle = useMemo(() => {
        const index = value.length;
        value[index] = undefined;
        return (title?: PageTitle) => {
            value[index] = title;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const title = factory();
    useEffect(
        () => {
            setThisTitle(title);
            rerender();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [...(deps ?? [])]
    );

    useEffect(
        () => {
            return () => {
                value.pop();
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return value;
}

function renderTitlePart<TResult>(
    part: PageTitlePart,
    renderPart: (props: { value: string; path?: string }) => TResult,
    translation: ReturnType<typeof useTranslation>,
    query: UseUrl["query"]
) {
    const { t } = translation;

    if (!part) part = { value: "" };
    else if (typeof part === "string") part = { key: part };

    let value: string;
    let path: string | null | undefined;
    if ("key" in part) {
        value = t(part.key, part.args);
        path = part.path;
    } else {
        value = part.value;
        path = part.path;
    }

    if (path && part.keepQuery) {
        const qs = query.toString(part.keepQuery);
        if (path.includes("?")) path = `${path}&${qs}`;
        else path = `${path}?${qs}`;
    }

    return renderPart({ value, path });
}

export function useTitlePartRenderer<TResult>(part: PageTitlePart, renderPart: (props: { value: string; path?: string }) => TResult): TResult {
    const { query } = useUrl();
    return renderTitlePart(part, renderPart, useTranslation(), query);
}

export function useTitleRenderer<TPartResult, TCombinedResult>(
    title: PageTitle,
    renderPart: (props: { value: string; path?: string }) => TPartResult,
    partition: TPartResult,
    combiner: (renderedParts: TPartResult[]) => TCombinedResult
) {
    const translation = useTranslation();
    const { query } = useUrl();

    if (!Array.isArray(title)) title = [title];

    const renderedParts = title
        .filter((v) => v)
        .flatMap((t, idx) =>
            idx === 0 ? [renderTitlePart(t, renderPart, translation, query)] : [partition, renderTitlePart(t, renderPart, translation, query)]
        );

    const combinedTitle = combiner(renderedParts);
    return combinedTitle;
}
