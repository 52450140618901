import { Dialog, DialogProps } from "@mui/material";
import { PropsWithChildren } from "react";
import { SlideUpTransition } from "../SlideUpTransition";

type FlFullscreenDialogProps = PropsWithChildren<{
    onClose: () => void;
    open: boolean;
}> &
    Omit<DialogProps, "TransitionComponent" | "onClose" | "open">;

const FlFullscreenDialog = (props: FlFullscreenDialogProps) => {
    const { open, onClose, children, ...rest } = props;
    return (
        <Dialog
            TransitionComponent={SlideUpTransition}
            fullScreen
            onClose={onClose}
            open={open}
            {...rest}
        >
            {children}
        </Dialog>
    );
};

export default FlFullscreenDialog;
