import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import i18next from "i18next";
import React from "react";
import ReactDOM from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import { RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./axios";
import "./global.css";
import "./i18next";
import FluenetQueryClientProvider from "./queries";
import "./reset.css";
import router from "./router";
import FlThemeProvider from "./theme";
import "./tools/logging";
import "./zodSetup";

ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
        <FlThemeProvider>
            <I18nextProvider i18n={i18next}>
                <FluenetQueryClientProvider>
                    <ReactQueryDevtools initialIsOpen={false} />
                    <RouterProvider router={router} />
                    <ToastContainer />
                </FluenetQueryClientProvider>
            </I18nextProvider>
        </FlThemeProvider>
    </React.StrictMode>,
);
