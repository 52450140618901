import { useMemo } from "react";
import { useIdentity, Permissions, RequiredPermission } from "./useIdentity";

export function usePermissions(): Permissions {
    const identity = useIdentity(false);

    if (!identity) return Permissions.loading;

    if (!identity.isAuthenticated) return Permissions.noAccess;

    return identity.permissions;
}

export function useHasPermission(permission: RequiredPermission, all: boolean = false) {
    const { has } = usePermissions();

    const isAllowed = useMemo(() => has(permission, all), [all, has, permission]);
    return isAllowed;
}
