import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import apps from "../../apps";
import { FlAppItems } from "../../components/Layout/FlAppSelector/FlAppItems";
import FlPage from "../../components/Layout/FlPage";
import FlStack from "../../designSystem/Layout/FlStack";

export default function SiteRoot() {
    const { t } = useTranslation();
    return (
        <FlPage title="features.basic.siteRoot.title">
            <Typography variant="body1">{t("features.basic.siteRoot.selectApp")}</Typography>
            <FlStack
                direction={"row"}
                flexWrap={"wrap"}
                spacing={2}
                sx={{
                    "@media (max-width: 600px)": {
                        alignItems: "center",
                        justifyContent: "center",
                    },
                }}
            >
                <FlAppItems
                    apps={apps}
                    t={t}
                />
            </FlStack>
        </FlPage>
    );
}
