import { forwardRef } from "react";
import FlButton, { FlButtonProps } from "./FlButton";

export type FlSecondaryButtonProps = Omit<FlButtonProps, "variant" | "color">;

const FlSecondaryButton = forwardRef<HTMLButtonElement, FlSecondaryButtonProps>(function FlSecondaryButton({ children, ...props }, ref) {
    return (
        <FlButton
            {...props}
            color="primary"
            ref={ref}
            variant="outlined"
        >
            {children}
        </FlButton>
    );
});

export default FlSecondaryButton;
