import { Avatar, Typography } from "@mui/material";
import { forwardRef, useMemo } from "react";
interface FlInitialAvatarProps {
    name: string;
    size: number;
}
const FlInitialAvatar = forwardRef<HTMLDivElement, FlInitialAvatarProps>(function FlInitialAvatar(props, ref) {
    const initials = useMemo(() => getInitials(props.name), [props.name]);

    return (
        <Avatar
            ref={ref}
            sx={{ width: props.size, height: props.size, bgcolor: stringToColour(props.name) }}
        >
            <Typography
                sx={{ color: "inherit" }}
                variant="caption"
            >
                {initials}
            </Typography>
        </Avatar>
    );
});

const stringToColour = (str: string) => {
    let hash = 0;
    str.split("").forEach((char) => {
        hash = char.charCodeAt(0) + ((hash << 5) - hash);
    });
    let colour = "#";
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & 0xff;
        colour += value.toString(16).padStart(2, "0");
    }
    return colour;
};
function getInitials(name: string | null): string {
    const initials = (name ?? "")
        .split(" ")
        .map((s) => s[0])
        .join("")
        .toUpperCase();

    return initials.substring(0, 2);
}

export default FlInitialAvatar;
