import { styled } from "@mui/material";
import { NavLink } from "react-router-dom";
import FlText from "../../../designSystem/DataDisplay/FlText";

interface AppItemProps {
    route: string;
    name: string;
    icon: JSX.Element;
    autoFocus: boolean;
    onClick?: () => void;
}

export function FlAppItem(props: AppItemProps) {
    const StyledDiv = styled("div")(({ theme }) => ({
        display: "flex",
        width: "100px",
        height: "50px",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        color: theme.palette.text.primary,
        overflow: "hidden",
        padding: "1rem",
        borderRadius: ".5rem",
        "&:hover, &.active": {
            color: theme.palette.primary.main,
            backgroundColor: theme.palette.action.hover,
        },
    }));

    const StyledNavLink = styled(NavLink)(({ theme }) => ({
        textDecoration: "none",
        "&.active": {
            "& > div": {
                color: theme.palette.primary.main,
                backgroundColor: theme.palette.action.hover,
            },
        },
    }));

    return (
        <StyledNavLink
            autoFocus={props.autoFocus}
            className={({ isActive }) => (isActive ? "active" : "")}
            onClick={props.onClick}
            to={props.route}
        >
            <StyledDiv>
                {props.icon}
                <FlText>{props.name}</FlText>
            </StyledDiv>
        </StyledNavLink>
    );
}
