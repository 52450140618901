import anylogger, { Logger } from "anylogger";
import axios from "axios";
import { destructure } from "./destructure";

type LogEntryContext = {
    name: string;
    value: unknown;
    destructure?: boolean;
};

type LogRequest = {
    level: string;
    message: string;
    args?: unknown[];
    contexts?: LogEntryContext[];
    retries: number;
};

let events: LogRequest[] = [];
function logEvent(level: string, message: string, args: unknown[] = [], contexts: LogEntryContext[] = []) {
    events.push({
        level,
        message,
        args: destructure(args),
        contexts: contexts.map((c) => (c.destructure === false ? c : { name: c.name, value: destructure(c.value) })),
        retries: 0,
    });
}

function getDefaultContexts(): LogEntryContext[] {
    return [{ name: "frontendVersion", value: import.meta.env.VITE_VERSION }];
}

anylogger.ext = function (logger: Logger) {
    function createLoggerFn(level: string) {
        return function (message: string, ...args: unknown[]) {
            try {
                const contexts: LogEntryContext[] = getDefaultContexts();
                if (args.length) contexts.push({ name: "args", value: args, destructure: true });

                logEvent(`${level}`, `${message}`, undefined, contexts);
            } catch (ex) {
                consoleError("Error logging event", ex);
            }
        };
    }

    logger.enabledFor = (level: string) => level in ["error", "warn", "info", "log"];

    logger.debug = () => {};
    logger.trace = () => {};

    logger.info = createLoggerFn("info");
    logger.log = createLoggerFn("info");
    logger.warn = createLoggerFn("warn");
    logger.error = createLoggerFn("error");

    return logger;
};

const consoleLogger = anylogger("console");

const consoleLog = console.log;
console.log = function (message: string, ...args: unknown[]) {
    consoleLogger.log(message, ...args);
    consoleLog(message, ...args);
};

const consoleError = console.error;
console.error = function (message: string, ...args: unknown[]) {
    consoleLogger.error(message, ...args);
    consoleError(message, ...args);
};

const consoleWarn = console.warn;
console.warn = function (message: string, ...args: unknown[]) {
    consoleLogger.warn(message, ...args);
    consoleWarn(message, ...args);
};

const consoleInfo = console.info;
console.info = function (message: string, ...args: unknown[]) {
    consoleLogger.info(message, ...args);
    consoleInfo(message, ...args);
};

const consoleDebug = console.debug;
console.debug = function (message: string, ...args: unknown[]) {
    consoleLogger.debug(message, ...args);
    consoleDebug(message, ...args);
};

function postLogEvents() {
    const localEvents = events;
    if (localEvents.length !== 0) {
        events = [];

        axios.post("/api/log", localEvents).catch((e) => {
            consoleError("Failed to post log events", e);
            events.push(...localEvents.filter((e) => e.retries > 3).map((e) => ({ ...e, retries: e.retries + 1 })));
        });
    }

    setTimeout(postLogEvents, 1000);
}
postLogEvents();
