import { Business } from "@mui/icons-material";
import { forwardRef } from "react";

interface FlOfficeIconProps {}

const FlOfficeIcon = forwardRef<SVGSVGElement, FlOfficeIconProps>(function FlOfficeIcon(_, ref) {
    return <Business ref={ref} />;
});

export default FlOfficeIcon;
