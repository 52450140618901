import { z } from "zod";
import { $t } from "./i18next";

const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
    if (issue.code === "invalid_type" && issue.received === "undefined") return { message: $t("common.zodErrors.required") };

    return { message: ctx.defaultError };
};

z.setErrorMap(customErrorMap);
