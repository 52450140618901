import { SupportAgent } from "@mui/icons-material";
import { forwardRef } from "react";

interface FlCustomerSupportIconProps {}

const FlCustomerSupportIcon = forwardRef<SVGSVGElement, FlCustomerSupportIconProps>(function FlCustomerSupportIcon(_, ref) {
    return <SupportAgent ref={ref} />;
});

export default FlCustomerSupportIcon;
