import { Factory } from "@mui/icons-material";
import { forwardRef } from "react";

interface FlProductionIconProps {}

const FlProductionIcon = forwardRef<SVGSVGElement, FlProductionIconProps>(function FlProductionIcon(_, ref) {
    return <Factory ref={ref} />;
});

export default FlProductionIcon;
