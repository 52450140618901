import { Destructurer } from "./types";

export const objectDestructurer: Destructurer = (o, destructurer) => {
    if (!o) return o;

    if (Array.isArray(o)) {
        return o.map((value) => destructurer(value));
    }

    if (typeof o !== "object") {
        return o;
    }

    if ("toJSON" in o && typeof o.toJSON === "function") {
        return o.toJSON();
    }

    return Object.entries(o).reduce((acc, [key, value]) => {
        return {
            ...acc,
            [key]: destructurer(value),
        };
    }, {});
};
