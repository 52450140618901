import { useMemo } from "react";
import FlInitialAvatar from "../../designSystem/DataDisplay/FlInitialAvatar";
import { Identity } from "../../features/security/useIdentity";

const FlIdentityAvatar = (props: { identity: Identity | null }) => {
    const identity = props.identity;

    const identityName = useMemo(() => {
        if (!identity) return "";

        if (!identity.isAuthenticated) return "";

        return identity.name;
    }, [identity]);

    return (
        <FlInitialAvatar
            name={identityName}
            size={32}
        />
    );
};

export default FlIdentityAvatar;
