import { PersonPinCircle } from "@mui/icons-material";
import { forwardRef } from "react";

interface FlVisitsIconProps {}

const FlVisitsIcon = forwardRef<SVGSVGElement, FlVisitsIconProps>(function FlVisitsIcon(_, ref) {
    return <PersonPinCircle ref={ref} />;
});

export default FlVisitsIcon;
