import { ReactNode } from "react";
import { RequiredPermission } from "./useIdentity";
import { useHasPermission } from "./usePermissions";

export type FlRequirePermissionProps = {
    permission: RequiredPermission;
    all?: boolean;
    children?: ReactNode | ((isAllowed: boolean) => ReactNode) | undefined;
};

export default function FlRequirePermission({ permission, all, children }: FlRequirePermissionProps) {
    const isAllowed = useHasPermission(permission, all ?? false);
    if (isAllowed === null) return;

    if (typeof children === "function") {
        return children(isAllowed);
    }

    if (isAllowed) return children;
}
