import AppsIcon from "@mui/icons-material/Apps";
import { forwardRef } from "react";

interface FlAppsIconProps {}

const FlAppsIcon = forwardRef<SVGSVGElement, FlAppsIconProps>(function FlAppsIcon(_, ref) {
    return <AppsIcon ref={ref} />;
});

export default FlAppsIcon;
