import { SquareFoot } from "@mui/icons-material";
import { forwardRef } from "react";

interface FlSurveyorIconProps {}

const FlSurveyorIcon = forwardRef<SVGSVGElement, FlSurveyorIconProps>(function FlSurveyorIcon(_, ref) {
    return <SquareFoot ref={ref} />;
});

export default FlSurveyorIcon;
