import { AirportShuttle } from "@mui/icons-material";
import { forwardRef } from "react";

interface FlFleetManagementIconProps {}

const FlFleetManagementIcon = forwardRef<SVGSVGElement, FlFleetManagementIconProps>(function FlFleetManagementIcon(_, ref) {
    return <AirportShuttle ref={ref} />;
});

export default FlFleetManagementIcon;
