import React, { useEffect } from "react";
import { PageTitle, useCurrentPageTitle, useTitleRenderer } from "../../hooks/usePageTitle";
import FlAccountMenu from "./FlAccountMenu";
import FlAppSelector from "./FlAppSelector";
import FlPageBase from "./FlPageBase";

type FlPageProps = React.PropsWithChildren<{
    title?: PageTitle;
}>;

export default function FlPage(props: FlPageProps) {
    return (
        <FlPageBase
            {...props}
            tools={
                <>
                    <RenderDocumentTitle />
                    <FlAppSelector />
                    <FlAccountMenu />
                </>
            }
        />
    );
}

export function FlPageDecorator({ title }: { title?: PageTitle }) {
    const FlPageDecoratorInner = (Story: () => JSX.Element) => (
        <FlPage title={title}>
            <Story />
        </FlPage>
    );
    return FlPageDecoratorInner;
}

function RenderDocumentTitle() {
    const parts = useCurrentPageTitle();

    const documentTitle = useTitleRenderer(
        parts,
        ({ value }) => value,
        ">",
        (p) => p.join(" ")
    );

    // Setting the title of the page
    useEffect(() => {
        document.title = documentTitle;
    }, [documentTitle]);

    return <></>;
}
