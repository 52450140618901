import { Link as MuiLink } from "@mui/material";
import { PropsWithChildren, useMemo } from "react";
import { useHref, useLinkClickHandler } from "react-router-dom";
import FlPrimaryButton from "../Inputs/FlPrimaryButton";
import FlSecondaryButton from "../Inputs/FlSecondaryButton";
export type FlLinkProps = PropsWithChildren<{
    to: string;
    text?: string;
    button?: boolean | "primary" | "secondary";
}>;

export default function FlLink({ to, text, children, button }: FlLinkProps) {
    const href = useHref(to);
    const handleClick = useLinkClickHandler(to);

    const actualChildren = useMemo(() => {
        const actualChildren = text ?? children;

        switch (button) {
            case true:
            case "primary":
                return <FlPrimaryButton>{actualChildren}</FlPrimaryButton>;
            case "secondary":
                return <FlSecondaryButton>{actualChildren}</FlSecondaryButton>;
            default:
                return actualChildren;
        }
    }, [button, children, text]);

    return (
        <MuiLink
            color={"inherit"}
            href={href}
            onClick={handleClick}
            underline="hover"
        >
            {actualChildren}
        </MuiLink>
    );
}
