import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import SiteRoot from "./features/basic/SiteRoot";

const router = createBrowserRouter(
    createRoutesFromElements(
        <>
            <Route
                lazy={() => import("./pages/LoginPage")}
                path="login"
            />
            <Route
                lazy={() => import("./pages")}
                path=""
            >
                <Route
                    element={<SiteRoot />}
                    index
                />

                <Route
                    lazy={() => import("./pages/LoggedInPage")}
                    path="logged-in"
                />

                <Route path="customers">
                    <Route
                        index
                        lazy={() => import("./pages/CustomerSupport/Customers/Find")}
                    />

                    <Route
                        lazy={() => import("./pages/CustomerSupport/Customers/Create")}
                        path="create"
                    />

                    <Route
                        lazy={() => import("./pages/CustomerSupport/Customers/View")}
                        path=":customerId/*"
                    >
                        <Route
                            lazy={() => import("./pages/CustomerSupport/Customers/Inquiries/List")}
                            path=""
                        />

                        <Route
                            lazy={() => import("./pages/CustomerSupport/Customers/Inquiries/View")}
                            path="inquiries/:inquiryId/"
                        />
                    </Route>

                    <Route
                        lazy={() => import("./pages/CustomerSupport/Customers/Update")}
                        path=":customerId/edit"
                    />
                </Route>

                <Route path="office">
                    <Route
                        index
                        lazy={() => import("./pages/Office/Overview")}
                    />
                </Route>

                <Route path="production">
                    <Route
                        index
                        lazy={() => import("./pages/Production/Overview")}
                    />
                </Route>

                <Route path="visits">
                    <Route
                        index
                        lazy={() => import("./pages/CustomerSupport/Visits/Find")}
                    />
                    <Route
                        lazy={() => import("./pages/CustomerSupport/Visits/View")}
                        path=":visitId"
                    />
                    <Route
                        lazy={() => import("./pages/CustomerSupport/Visits/Update")}
                        path=":visitId/edit"
                    />
                </Route>

                <Route path="users">
                    <Route
                        index
                        lazy={() => import("./pages/Administration/Users/Find")}
                    />
                    <Route
                        lazy={() => import("./pages/Administration/Users/Create")}
                        path="create"
                    />
                    <Route
                        lazy={() => import("./pages/Administration/Users/View")}
                        path=":userId"
                    />
                    <Route
                        lazy={() => import("./pages/Administration/Users/Update")}
                        path=":userId/edit"
                    />
                </Route>

                <Route path="fleet">
                    <Route
                        index
                        lazy={() => import("./pages/Administration/Fleet/Find")}
                    />

                    <Route
                        lazy={() => import("./pages/Administration/Fleet/Create")}
                        path="create"
                    />

                    <Route
                        lazy={() => import("./pages/Administration/Fleet/View")}
                        path=":vehicleId"
                    />

                    <Route
                        lazy={() => import("./pages/Administration/Fleet/Update")}
                        path=":vehicleId/edit"
                    />
                </Route>

                <Route path="route-planning">
                    <Route
                        index
                        lazy={() => import("./pages/RoutePlanning/Overview")}
                    />
                    <Route
                        lazy={() => import("./pages/RoutePlanning/Planning")}
                        path="routes"
                    />
                </Route>

                <Route path="installations">
                    <Route
                        lazy={() => import("./pages/Installations/InstallationsOverview")}
                        path=":visitId"
                    />

                    <Route
                        lazy={() => import("./pages/Installations/NewSalesQuote")}
                        path=":visitId/sales-quote"
                    />

                    <Route
                        lazy={() => import("./pages/Installations/ViewSalesQuote")}
                        path=":visitId/sales-quote/:salesQuoteId"
                    />

                    <Route
                        lazy={() => import("./pages/Installations/ViewSalesOrder")}
                        path=":visitId/sales-order/:salesOrderId"
                    />
                </Route>

                <Route path="surveyor">
                    <Route
                        index
                        lazy={() => import("./pages/Surveyor/Find")}
                    />

                    <Route
                        lazy={() => import("./pages/Surveyor/NewMeasurement")}
                        path=":visitId/new-measurement"
                    />

                    <Route
                        lazy={() => import("./pages/Surveyor/EditMeasurement")}
                        path=":visitId/edit-measurement/:flyscreenId"
                    />
                </Route>

                <Route path="packing">
                    <Route
                        lazy={() => import("./pages/Packing/Overview")}
                        index
                    />
                </Route>

                <Route
                    lazy={() => import("./views/NotFound")}
                    path="*"
                />
            </Route>
        </>,
    ),
);

export default router;
