import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { whoAmIContext } from "./whoAmIQueryContext";

function hasRequiredPermissions(
    allowedPermissions: string[] | null | undefined,
    requiredPermissions: RequiredPermission,
    someOrEvery: (predicate: (s: string) => boolean) => boolean
) {
    let isAllowed = false;
    if (allowedPermissions) {
        if (!requiredPermissions) {
            isAllowed = true;
        } else if (typeof requiredPermissions === "string") {
            isAllowed = allowedPermissions.includes(requiredPermissions);
        } else {
            isAllowed = requiredPermissions.length === 0 || someOrEvery.call(requiredPermissions, (f) => allowedPermissions.includes(f));
        }
    }

    console.debug("hasRequiredPermissions", { allowedPermissions, requiredPermissions, someOrEvery, isAllowed });
    return isAllowed;
}

export class Permissions {
    public static loading: Permissions = new Permissions([], true);
    public static noAccess: Permissions = new Permissions([], false);

    public isLoading: boolean;
    public permissions: string[];

    constructor(permissions: string[], isLoading: boolean) {
        this.permissions = permissions;
        this.isLoading = isLoading;
    }

    public hasAny = (requiredPermissions: RequiredPermission): boolean | null =>
        this.isLoading ? null : hasRequiredPermissions(this.permissions, requiredPermissions, Array.prototype.some);

    public hasAll = (requiredPermissions: RequiredPermission): boolean | null =>
        this.isLoading ? null : hasRequiredPermissions(this.permissions, requiredPermissions, Array.prototype.every);

    public has = (requiredPermissions: RequiredPermission, all: boolean = false): boolean | null =>
        all ? this.hasAll(requiredPermissions) : this.hasAny(requiredPermissions);
}

export type RequiredPermission = string | string[] | null | undefined;

type AuthenticatedIdentity = {
    userId: string;
    name: string;
    permissions: Permissions;
    isAuthenticated: true;
};
type UnauthenticatedIdentity = { isAuthenticated: false };
export type Identity = AuthenticatedIdentity | UnauthenticatedIdentity;

export function useIdentity(throwIfUnauthenticated: true): AuthenticatedIdentity | null;
export function useIdentity(): AuthenticatedIdentity | null;
export function useIdentity(throwIfUnauthenticated: false): Identity | null;
export function useIdentity(throwIfUnauthenticated: boolean = true): Identity | null {
    const { key: whoAmIContextKey, value: whoAmIContextQuery } = useContext(whoAmIContext);

    const { data } = useQuery<Identity | null>({
        queryKey: ["security/who-am-i", whoAmIContextKey],
        queryFn: async () => {
            const response = typeof whoAmIContextQuery === "function" ? await whoAmIContextQuery() : whoAmIContextQuery;
            console.debug("whoAmIQuery response", response);
            if (response)
                return {
                    isAuthenticated: true,
                    userId: response.userId,
                    name: response.name,
                    permissions: new Permissions(response.permissions.concat(response.roles), false),
                };

            return { isAuthenticated: false };
        },
        staleTime: 1000 * 60 * 2,
        refetchInterval: 1000 * 60 /* 1 minutes */,
    });

    if (!data) return null;

    if (!data.isAuthenticated && throwIfUnauthenticated) throw new Error("Not authenticated");

    return data;
}
