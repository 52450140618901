import { IconButton, Popover, styled, useMediaQuery } from "@mui/material";
import FocusTrap from "@mui/material/Unstable_TrapFocus";
import { useTheme } from "@mui/system";
import { createRef, useState } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { useTranslation } from "react-i18next";
import { FlApp } from "../../../apps";
import FlAppsIcon from "../../../designSystem/Icons/FlAppsIcon";
import { FlAppItems } from "./FlAppItems";
import InlineAppItems from "./InlineAppItems";

export type FlAppSelectorViewProps = {
    apps: FlApp[];
};

const StyledMenu = styled(Popover)(() => ({
    "& .MuiPaper-root": {
        borderRadius: "1rem",
        width: "calc((132px) * 3 + (24px * 2) + (1rem))",
    },
}));

export default function FlAppSelectorView(props: FlAppSelectorViewProps) {
    const { t } = useTranslation();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const isMenuOpen = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const menuButtonRef = createRef<HTMLButtonElement>();

    useHotkeys(
        "alt+a",
        () => {
            if (isMenuOpen) handleClose();
            else menuButtonRef.current?.click();
        },
        { enableOnFormTags: true },
    );

    const theme = useTheme();
    const shouldRenderAppsAsIcons = useMediaQuery(theme.breakpoints.up("md"));

    return (
        <>
            {!shouldRenderAppsAsIcons ? (
                <IconButton
                    onClick={handleClick}
                    ref={menuButtonRef}
                >
                    <FlAppsIcon />
                </IconButton>
            ) : (
                <InlineAppItems apps={props.apps} />
            )}
            <FocusTrap open={isMenuOpen}>
                <StyledMenu
                    anchorEl={anchorEl}
                    anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                    onClose={handleClose}
                    open={isMenuOpen}
                    slotProps={{
                        paper: {
                            elevation: 3,
                        },
                    }}
                    transformOrigin={{ horizontal: "center", vertical: "top" }}
                >
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            maxHeight: "300px",
                            overflowY: "auto",
                            padding: "1.5rem",
                            gap: ".5rem",
                        }}
                    >
                        <FlAppItems
                            apps={props.apps}
                            onAppClicked={handleClose}
                            t={t}
                        />
                    </div>
                </StyledMenu>
            </FocusTrap>
        </>
    );
}
